import { email, max, mimes, min_value as minValue, regex, required, size } from 'vee-validate/dist/rules'
import { extend, localize, setInteractionMode } from 'vee-validate'

import { cultureFormatDate } from '@/config/constants'
import { dateCompare } from '@/helpers'
import es from 'vee-validate/dist/locale/es.json'
import { format } from 'date-fns'
import { i18n } from '@/plugins/i18n/index'
import { toDate } from '@/utils'

setInteractionMode('lazy')
localize(es)

extend('required', {
  ...required,
  message: i18n.tc('FieldRequired')
})

extend('max', {
  ...max,
  message: i18n.tc('FieldMax')
})

extend('email', {
  ...email,
  message: i18n.tc('FieldEmail')
})
extend('mimes', {
  ...mimes,
  message: i18n.tc('FieldMimes')
})
extend('size', {
  ...size,
  message: i18n.tc('FieldSize')
})
extend('min_value', {
  ...minValue,
  message: (field, data) => {
    return i18n.tc('MinValue', 1, data)
  }
})
extend('regex', {
  ...regex,
  message: i18n.tc('FieldRegex')
})

extend('date_is_earlier', {
  validate: (value, { startDate, endDate }) => dateCompare({ startDate, endDate, validationType: 'earlier' }),
  params: ['startDate', 'endDate'],
  message: (value, { _startDate_, _endDate_ }) => {
    return i18n.tc('DateEarlierMessage', 0, { startDate: format(toDate(_startDate_), cultureFormatDate), endDate: format(toDate(_endDate_), cultureFormatDate) })
  }
})

extend('date_is_beyond', {
  validate: (value, { startDate, endDate }) => {
    const result = dateCompare({ startDate, endDate, validationType: 'beyond' })
    return result
  },
  params: ['startDate', 'endDate'],
  message: (value, { _startDate_, _endDate_ }) => {
    return i18n.tc('DateBeyondMessage', 0, { startDate: format(toDate(_startDate_), cultureFormatDate), endDate: format(toDate(_endDate_), cultureFormatDate) })
  }
})
