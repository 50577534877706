import { format, isValid, parse, parseISO, parseJSON } from 'date-fns'

import { cultureFormatIsoDate } from '@/config/constants'

/**
 * Description placeholder
 * @author waguirreo
 *
 * @param {*} value
 * @returns {*}
 */
const toDate = value => {
  let valueParsed
  if (typeof value === 'string' && isValid(parseISO(value))) {
    valueParsed = parseISO(value)
  } else if (typeof value === 'string' && isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
    valueParsed = parse(value, 'dd/MM/yyyy', new Date())
  } else if (typeof value === 'string' && isValid(parseJSON(value))) {
    valueParsed = parseJSON(value)
  } else if (typeof value === 'object' && isValid(value) && value.getFullYear()) {
    valueParsed = value
  }
  valueParsed?.setHours(0, 0, 0, 0)
  return valueParsed
}

export const formatToDate = (date, cultureFormat = 'dd/MM/yyyy') => {
  return format(toDate(date), cultureFormat)
}

export const formatToIsoDate = (date) => {
  return format(toDate(date), cultureFormatIsoDate)
}

export default toDate
