const OrderDetailType = {
  PERIODICIDAD: 'Periodicidad',
  APERTURA: 'Apertura',
  CAMBIO_PLAN: 'CambioPlan',
  ADICIONAR_CARACTERISTICA: 'AdicionarCaracteristica',
  PAQUETE: 'Paquete',
  PRORRATEO: 'Prorrateo',
  OTRO: 'Otro'
}

export default OrderDetailType
