// import { useToast } from '@/@core/utils/toast'

import { i18n } from '@/plugins/i18n'
import { toast } from '@/@core/utils/toast'

export default {
  info (message) {
    toast.info(`${i18n.tc('Info')}!: ${message}`, {
      content: message
    })
  },
  validateForm (message) {
    toast.info(`${i18n.tc('Info')}!: ${message || i18n.tc('ValidateFormHelper')}`, {
      content: message || i18n.tc('ValidateFormHelper')
    })
  },
  successCreated (message) {
    toast.success(`${i18n.tc('Ok')}!: ${i18n.tc('SuccessCreated')}`, {
      content: message || i18n.tc('SuccessCreated')
    })
  },
  successSaved (message) {
    toast.success(`${i18n.tc('Ok')}!: ${i18n.tc('SuccessSaved')}`, {
      content: message || i18n.tc('SuccessSaved')
    })
  },
  successDeleted (message) {
    toast.success(`${i18n.tc('Ok')}!: ${i18n.tc('SuccessDeleted')}`, {
      content: message || i18n.tc('SuccessDeleted')
    })
  },
  success (message) {
    toast.success(`${i18n.tc('Ok')}!: ${i18n.tc('Success')}`, {
      content: message || i18n.tc('Success')
    })
  },
  error (message) {
    toast.error(`${i18n.tc('Opps')}!: ${message || i18n.tc('OppsHelper')}`, {
      content: message || i18n.tc('OppsHelper')
    })
  },
  warning (message) {
    toast.warning(`${i18n.tc('Warning')}!: ${message || i18n.tc('WarningHelper')}`, {
      content: message || i18n.tc('WarningHelper')
    })
  }
}
