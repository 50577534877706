const OrderStatus = {
  ANNULLED: 'Annulled',
  OPEN: 'Open',
  PAID: 'Paid',
  REFUNDED: 'Refunded',
  FREE: 'Free',
  PENDING_BILLING: 'PendingBilling',
  PURCHASE_OPEN: 'PurchaseOpen'
}

export default OrderStatus
