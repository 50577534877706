import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import { camelCase } from 'lodash'
import { computed } from 'vue'
import { createStore } from 'vuex'
import app from './app'
import user from './modules/users'

const store = createStore({
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user
  }
})

export default store

export function useStore () {
  return store
}

export function registerModule (moduleName, module) {
  if (!store.hasModule(moduleName)) {
    return store.registerModule(moduleName, module)
  }
}

export function unRegisterModule (moduleName) {
  if (store.hasModule(moduleName)) {
    store.unregisterModule(moduleName)
  }
}

export const mapState = () => Object.fromEntries(
  Object.keys(store.state).map(
    key => [key, computed(() => store.state[key])]
  )
)

export const mapGetters = getters => Object.fromEntries(
  getters.map(
    getter => [camelCase(getter), computed(() => store.getters[getter])]
  )
)

export function mapActions (actions) {
  const store = useStore()

  return Object.keys(actions).reduce((mappedActions, action) => {
    mappedActions[action] = (...args) => store.dispatch(actions[action], ...args)
    return mappedActions
  }, {})
}
export function mapActionsModule (actions, moduleName) {
  // const store = useStore()
  console.log('%c⧭', 'color: #5200cc', store)

  console.log('%c⧭', 'color: #9c66cc', actions)
  return Object.keys(actions).reduce((mappedActions, action) => {
    console.log('%c⧭', 'color: #607339', mappedActions, action)
    mappedActions[action] = (...args) => store.dispatch(`${moduleName}/${actions[action]}`, ...args)
    return mappedActions
  }, {})
}
