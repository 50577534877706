import {
  AbilityBuilder,
  createMongoAbility
} from '@casl/ability'

// const conditionsMatcher = buildMongoQueryMatcher({ $nor }, { nor })

export const MODULES_ACTION = {
  CREATE: 'create',
  DELETE: 'delete',
  DETAIL: 'detail',
  MANAGE: 'manage',
  READ: 'read',
  UPDATE: 'update',
  SEND: 'send',
  CANCEL: 'cancel'
}

export const MODULES_NAME = {
  COMPANIES: 'Companies',
  COMPANY_PLAN: 'CompanyPlan',
  COMPANY_SIGNATURE: 'CompanySignature',
  COMPANY_STUDENTS: 'CompanyStudents',
  COMPANIES_SUMMARY: 'CompanySummary',
  COMPANY_PERSON: 'CompanyPerson',
  COMPANY_VENDOR: 'CompanyVendor',
  DASHBOARD_CRM: 'DashboardCrm',
  DASHBOARD_SALES: 'DashboardSales',
  DASHBOARD_SUBSCRIPTIONS: 'DashboardSubscriptions',
  ORDER: 'Order',
  ORDERS: 'Orders',
  ORDER_PERIOD_BILLING: 'OrderPeriodBilling',
  ORDERS_SELLER: 'OrdersSeller',
  PRODUCTS: 'Products',
  PLANS: 'Plans',
  PLAN_FEATURES: 'PlansFeatures',
  ADD_ONS: 'AddOns',
  COUPONS: 'Coupons',
  FEATURES: 'Features',
  SUBSCRIPTION_FEATURES: 'SubscriptionFeatures',
  SUBSCRIPTION_PRICE: 'SubscriptionPrice',
  SUBSCRIPTION_FEATURES_ADDON: 'SubscriptionFeaturesAddOn',
  SUBSCRIPTION_FEATURES_ADDON_PRICE: 'SubscriptionFeaturesprice',
  SUBSCRIPTION_STATUS: 'SubscriptionStatus',
  SUBSCRIPTION_PACKAGES: 'SubscriptionPackages',
  SUBSCRIPTION_PACKAGES_DUES: 'SubscriptionPackagesDues',
  SUBSCRIPTION_LOG_ROOT: 'SubscriptionLogRoot',
  SUBSCRIPTION_LOG: 'SubscriptionLog',
  SUBSCRIPTION: 'Subscription',
  SUBSCRIPTION_END_TRIAL_PERIOD: 'SubscriptionEndTrialPeriod',
  SUBSCRIPTION_CREATE_ORDER: 'SubscriptionCreateOrder',
  USERS_TENANT: 'UsersTenant',
  USERS_TENANT_VIEW: 'UsersTenantView',
  USERS_TENANT_ROLE: 'UsersTenantRole',
  USERS_TENANT_PROFILE: 'UsersTenantProfile',
  USERS_TENANT_STATUS: 'UsersTenantStatus',
  USERS_HUB: 'UsersHub',
  ORDERS_STATUS: 'OrdersStatus',
  ORDERS_PAYMENTS: 'OrdersPayment',
  ORDERS_PAYMENT_LINK: 'OrdersPaymentsLink',
  ORDERS_EMAIL_LOG: 'OrdersEmailLog',
  ONLY_ROOT_AND_ADMIN: 'OnlyRootAndAdmin',
  ORDER_DETAIL_OTHER: 'OrderDetailOther',
  ORDER_DETAIL_RECURRENCE: 'OrderDetailRecurrence',
  ORDER_DETAIL_OPENING: 'OrderDetailOpening',
  ORDER_DETAIL_PLAN_CHANGE: 'OrderDetailPlanChange',
  ORDER_DETAIL_ADD_ADDON: 'OrderDetailAddAddOn',
  ORDER_DETAIL_PACKAGE: 'OrderDetailPackage',
  ORDER_DETAIL_PRORATION: 'OrderDetailProration'
}

function subjectNameResolver (obj) {
  if (obj && obj.__typename) {
    return obj.__typename // Use the __typename field to determine the subject type
  }

  if (!obj || typeof obj === 'string') {
    return obj
  }

  return obj.__type
}

export function defineAbilitiesForRoot (userId = '') {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS, { status: { $in: ['Open'] } })
  can(MODULES_ACTION.CANCEL, MODULES_NAME.ORDERS, { status: { $in: ['Open'] } })
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS_SELLER)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_PAYMENT_LINK)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PERSON)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_VENDOR)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SALES)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SUBSCRIPTIONS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG_ROOT)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PRICE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES_ADDON_PRICE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES_DUES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_STATUS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_END_TRIAL_PERIOD)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_STATUS)
  can(MODULES_ACTION.SEND, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.CREATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.READ, MODULES_NAME.PLANS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.PLANS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PLANS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.PLANS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PLAN_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.READ, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.READ, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.CREATE, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.DELETE, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_CREATE_ORDER)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_USER_OWNER)
  can(MODULES_ACTION.READ, MODULES_NAME.ONLY_ROOT_AND_ADMIN)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_OTHER)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_RECURRENCE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_OPENING)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PLAN_CHANGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_ADD_ADDON)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PACKAGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PRORATION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDER_PERIOD_BILLING)

  return build({ subjectNameResolver })
}

export function defineAbilitiesForAdmin (userId = '') {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS, { status: { $in: ['Open'] } })
  can(MODULES_ACTION.CANCEL, MODULES_NAME.ORDERS, { status: { $in: ['Open'] } })
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS_SELLER)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_PAYMENT_LINK)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PERSON)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_VENDOR)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SALES)
  can(MODULES_ACTION.READ, MODULES_NAME.DASHBOARD_SUBSCRIPTIONS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG_ROOT)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PRICE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES_ADDON_PRICE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_PACKAGES_DUES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_STATUS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_END_TRIAL_PERIOD)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_HUB)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_STATUS)
  can(MODULES_ACTION.SEND, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.CREATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.READ, MODULES_NAME.PLANS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.PLANS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PLANS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.PLANS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.PLAN_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.READ, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.CREATE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.DELETE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.READ, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.CREATE, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.DELETE, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_CREATE_ORDER)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_USER_OWNER)
  can(MODULES_ACTION.READ, MODULES_NAME.ONLY_ROOT_AND_ADMIN)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_OTHER)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_RECURRENCE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_OPENING)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PLAN_CHANGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_ADD_ADDON)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PACKAGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PRORATION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDER_PERIOD_BILLING)

  return build({ subjectNameResolver })
}

export function defineAbilitiesForSupport (userId = '') {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)

  return build({ subjectNameResolver })
}
export function defineAbilitiesForSales (userId = '') {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS, { sellerUserId: userId, source: { $ne: 'Apertura' }, status: { $in: ['Open'] } })
  can(MODULES_ACTION.CANCEL, MODULES_NAME.ORDERS, { sellerUserId: userId, status: { $in: ['Open'] } })
  can(MODULES_ACTION.CREATE, MODULES_NAME.SUBSCRIPTION, { sellerUserId: userId })
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_END_TRIAL_PERIOD, { sellerUserId: userId })
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_OTHER)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PLAN_CHANGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_ADD_ADDON)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PACKAGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PRORATION)

  const rules = build({ subjectNameResolver })
  return rules
}

export function defineAbilitiesForDirectorSales (userId = '') {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS, { sellerUserId: { $ne: '' }, source: { $ne: 'Apertura' }, status: { $in: ['Open'] } })
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDERS_SELLER, { sellerUserId: { $ne: '' }, status: { $in: ['Open'] } })
  can(MODULES_ACTION.CANCEL, MODULES_NAME.ORDERS, { sellerUserId: { $ne: '' }, source: { $ne: 'Apertura' }, status: { $in: ['Open'] } })
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION, { sellerUserId: { $ne: '' } })
  can(MODULES_ACTION.UPDATE, MODULES_NAME.SUBSCRIPTION_END_TRIAL_PERIOD, { sellerUserId: { $ne: '' } })
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_PAYMENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_PERSON)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_VENDOR)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS)
  can(MODULES_ACTION.READ, MODULES_NAME.PRODUCTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_LOG)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.SEND, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.CREATE, MODULES_NAME.SUBSCRIPTION)
  can(MODULES_ACTION.READ, MODULES_NAME.PLANS)
  can(MODULES_ACTION.READ, MODULES_NAME.ADD_ONS)
  can(MODULES_ACTION.READ, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.READ, MODULES_NAME.FEATURES)
  can(MODULES_ACTION.CREATE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COUPONS)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_OTHER)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PLAN_CHANGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_ADD_ADDON)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PACKAGE)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDER_DETAIL_PRORATION)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.ORDER_PERIOD_BILLING)

  const rules = build({ subjectNameResolver })
  return rules
}

export function defineAbilitiesForLeaderSupport (userId = '') {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  can(MODULES_ACTION.DETAIL, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES_SUMMARY)
  can(MODULES_ACTION.READ, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.CREATE, MODULES_NAME.ORDERS_EMAIL_LOG)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_PLAN)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_FEATURES)
  can(MODULES_ACTION.READ, MODULES_NAME.SUBSCRIPTION_PACKAGES)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_SIGNATURE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.COMPANY_STUDENTS)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.CREATE, MODULES_NAME.USERS_TENANT)
  can(MODULES_ACTION.READ, MODULES_NAME.USERS_TENANT_VIEW)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_ROLE)
  can(MODULES_ACTION.UPDATE, MODULES_NAME.USERS_TENANT_PROFILE)
  can(MODULES_ACTION.SEND, MODULES_NAME.SUBSCRIPTION)

  return build({ subjectNameResolver })
}
