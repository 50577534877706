import vuetify from '@plugins/vuetify'
import { computed } from 'vue'

export const widthDialog = computed(() => {
  switch (vuetify.framework.breakpoint.name) {
    case 'xs':
      return '97vw'
    case 'sm':
      return '85vw'
    case 'md':
      return '45vw'
    case 'lg':
      return '35vw'
    case 'xl':
      return '25vw'
  }
  return '100vw'
})

export const widthDrawer = computed(() => {
  switch (vuetify.framework.breakpoint.name) {
    case 'xs':
      return '97vw'
    case 'sm':
      return '75vw'
    case 'md':
      return '65vw'
    case 'lg':
      return '45vw'
    case 'xl':
      return '35vw'
  }
  return '100vw'
})
