import * as data from 'vue-toastification'

export const toast = data.createToastInterface({
  position: data.POSITION.TOP_CENTER,
  timeout: 5844,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 2,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
})
