export const SubscriptionRecurrence = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
  NONE: 'None',
  // @deprecated Se mantiene para empresas antiguas, pero para nuevas compras no aplica
  QUARTERLY: 'Quarterly',
  // @deprecated Se mantiene para empresas antiguas, pero para nuevas compras no aplica
  SEMIANNUAL: 'Semiannual',
  // @deprecated
  THREEYEARLY: 'ThreeYearly',
  // @deprecated Se mantiene para empresas antiguas, pero para nuevas compras no aplica
  EIGHTHYEARLY: 'EighthYearly'
}

export default SubscriptionRecurrence
