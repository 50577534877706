import Vue from 'vue'
import VueRouter from 'vue-router'
import { ability } from '@config/ability'
import apps from './apps'
import dashboard from './dashboard'
import { mapGetters } from '@store'
import pages from './pages'

const { userIsAuthenticated } = mapGetters(['user/isAuthenticated'])

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
    meta: {
      requireIsLogged: true
      // action: MODULES_ACTION.READ,
      // subject: MODULES_NAME.DASHBOARD_SALES,
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public'
    }
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/confirm-account/:id/:code',
    name: 'account-confirm',
    component: () => import('@/views/ConfirmAccount.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true
    }
  },
  ...dashboard,
  ...apps,
  ...pages,
  {
    path: '*',
    redirect: 'error-404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  // if (!canNavigate(to)) {
  if (
    !userIsAuthenticated.value &&
    to.meta.requireIsLogged &&
    to.name !== 'auth-login'
  ) {
    return next({ name: 'auth-login' })
  }
  if (
    to.meta.requireIsLogged &&
    to.meta.action &&
    to.meta.subject &&
    to.name !== 'auth-login' &&
    !ability.can(to.meta.action, to.meta.subject)
  ) {
    return next({ name: 'auth-login' })
  }

  // If logged in => not authorized
  // return next({ name: 'misc-not-authorized' })

  // return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && userIsAuthenticated.value) {
    return next('/')
  }

  return next()
})

export default router
