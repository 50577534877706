<template>
  <layout-content-horizontal-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot />
    <!-- Navbar -->
    <template #navbar>
      <div class="navbar-content-container">
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">
          <!-- <app-bar-i18n /> -->
          <!-- <app-bar-theme-switcher class="mx-4" /> -->
          <!-- <app-bar-notification /> -->
          <app-bar-user-menu class="ms-2" />
        </div>
      </div>
      <v-overlay
        :value="shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      />
    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a
            href="https://aliaddo.com"
            class="text-decoration-none"
          >Aliaddo S.A.S</a>, Todos los
          derechos reservados</span>
        <div class="d-flex align-center">
          <span>Hecho a mano, hecho con </span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>

    <!-- App Content -->
    <template #v-app-content>
      <!-- <app-customizer class="d-none d-md-block" /> -->
    </template>
  </layout-content-horizontal-nav>
</template>

<script setup>
import { ability } from '@config/ability'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import { useStore } from '@store'
import { computed, onMounted } from 'vue'

// App Bar Components
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
// import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'

// Search Data

import menuItems from '@/navigation/horizontal'

const store = useStore()
const shallContentShowOverlay = computed(() => store.state.app.shallContentShowOverlay)
const navMenuItems = ref([])

onMounted(() => {
  // ability.can(MODULES_ACTION.READ, MODULES_NAME.COMPANIES)
  for (let index = 0; index < menuItems.length; index++) {
    const menu = menuItems[index]
    console.log('%c⧭', 'color: #364cd9', ability)
    if (!menu.subject || !menu.action || ability.can(menu.action, menu.subject)) {
      navMenuItems.value.push(menu)
    }
  }
})
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
