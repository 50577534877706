import router from '@/router'
import { notifier } from '@helpers'
import store from '@store'
import axios from 'axios'
import Vue from 'vue'

/* eslint-disable import/order */
// axios

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_HUB,

  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    accept: 'application/json'
  }
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    if (accessToken && !config.headers['X-NoAuthorization']) {
      config.headers.Authorization = `Bearer ${accessToken}`
      config.headers['X-Apigateway-Api-Userinfo'] = `Bearer ${accessToken}`
    }

    delete config.headers['X-NoAuthorization']

    config.params = {
      ...config.params,
      key: process.env.VUE_APP_API_HUB_KEY
    }

    // config.validateStatus = status => status < 207

    return config
  },
  error => Promise.reject(error)
)

// Add a response interceptor
axiosIns.interceptors.response.use(
  response => {
    if (
      response.status === 200 &&
      response.config &&
      response.config.method === 'post' &&
      !response.config.headers['x-hide-notification']
    ) {
      notifier.successCreated()
    } else if (
      response.status === 200 &&
      response.config &&
      response.config.method === 'put' &&
      !response.config.headers['x-hide-notification']
    ) {
      notifier.successSaved()
    } else if (
      response.status === 200 &&
      response.config &&
      response.config.method === 'patch' &&
      !response.config.headers['x-hide-notification']
    ) {
      notifier.successSaved()
    } else if (
      response.status === 200 &&
      response.config &&
      response.config.method === 'delete' &&
      !response.config.headers['x-hide-notification']
    ) {
      notifier.successDeleted()
    }

    return response
  },

  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  error => {
    if (error.response && error.response.data && error.response.data.detail) {
      notifier.error(error.response.data.detail)
    } else if (error.config && error.config.url.includes('api.ipify')) {
      console.error('api.ipify', error.response)
    } else if (error.isAxiosError && error.response && error.response.data && error.response.data.message) {
      notifier.error(error.isAxiosError && error.response.data.message)
    } else if (error.isAxiosError && error.response && error.response.data) {
      notifier.error(error.isAxiosError && error.response.data)
    } else if (error) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      notifier.error(error)

      if (error.isAxiosError && error.response && error.response.status === 401) {
        store.dispatch('user/setLogout')
        router.push('/login')
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
    }

    // Promise.reject(error)
    throw new Error(error)
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns
