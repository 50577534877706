const OrderSource = {
  DEMONIO: 'Demonio',
  COMPRA_PAQUETE: 'CompraPaquete',
  COMPRA_CAMBIO_PLAN: 'CompraCambioPlan',
  APERTURA: 'Apertura',
  COMERCIAL: 'Comercial',
  PANEL_ORDENES: 'PanelOrdenes'
}

export default OrderSource
