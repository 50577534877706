export default {
  'Account Settings': 'Account Settings',
  Action: 'Acción|Acciones',
  Actions: 'Actions',
  Active: 'Activo|Activas',
  Add: 'Crear',
  Push: 'Adicionar',
  AddCompany: 'Adicionar empresa',
  AddItem: 'Adicionar Item',
  AddOn: 'Complemento|Complementos',
  AddOnFeature: 'Características adicionales',
  AddressLine: 'Dirección',
  AdicionarCaracteristica: 'Adicionar una característica a la suscripción',
  Advance: 'Advance',
  Alert: 'Alert',
  All: 'Todo|Todos',
  AlreadyExistsCompany: 'Ya existe una empresa con los mismos datos en el servicio, por favor contacta a tu comercial para continuar el proceso.',
  AlreadyHaveAccount: 'Ya tiene una cuenta?',
  Analytics: 'Analytics',
  Annulled: 'Anulado',
  Apertura: 'Apertura de cuenta',
  'Apex Chart': 'Apex Chart',
  ApplyCoupon: 'Aplicar cupón',
  Approved: 'Aprobada',
  Apps: 'Apps',
  'APPS AND PAGES': 'APPS AND PAGES',
  Authentication: 'Authentication',
  Authorization: 'Autorización',
  AuthorizationCus: 'Autorización CUS',
  Autocomplete: 'Autocomplete',
  AvailableToPurchase: 'Disponible para compra en sitio',
  Avatar: 'Avatar',
  Back: 'Atrás',
  BackToLogin: 'Ir al inicio de sesión',
  Badge: 'Badge',
  Base: 'Base',
  Basic: 'Basic',
  BillingEndDate: 'Fin periodo facturación',
  BillingStartDate: 'Inicio periodo facturación',
  Bounce: 'Rebotado',
  Button: 'Button',
  ByCompany: 'Por Empresa',
  ByCus: 'Por Cus',
  ByDate: 'Por Fecha',
  ByName: 'Por Nombre',
  ByPeriod: 'Por Periodo',
  ByPlan: 'Por Plan',
  ByProduct: 'Por Producto',
  BySeller: 'Por Vendedor',
  BySignature: 'Firmas por aprobar',
  ByStatus: 'Por estado|Por estados',
  ByStatusSigner: 'Aceptación Firma|Aceptación Firmas',
  ByStatusStudent: 'Estudiantes',
  ByStudent: 'Cuenta estudiante|Cuenta estudiantes',
  ByUser: 'Usuario|Usuarios',
  Calendar: 'Calendar',
  CambioPlan: 'Cambio de plan',
  Cancel: 'Cancelar',
  Cards: 'Cards',
  CardStatus: 'Estado aceptación estudiante',
  Category: 'Categoría|Categoría',
  CcLegalRepresentative: 'Cédula representante legal',
  ChamberCommerce: 'Cámara y comercio',
  ChangeRol: 'Cambiar Rol',
  ChangeStatus: 'Cambiar estado',
  ChangeStatusHelper: 'Esta seguro de cambiar el estado a: {0}',
  Chart: 'Chart',
  Chartjs: 'Chartjs',
  Charts: 'Charts',
  'Charts & Maps': 'Charts & Maps',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Chat: 'Chat',
  Checkbox: 'Checkbox',
  Chip: 'Chip',
  City: 'Ciudad|Ciudades',
  CleanFilter: 'Limpiar filtros',
  Click: 'Click',
  ClickAddSubscription: 'Click para adicionar suscripción',
  Clicked: 'Click',
  ClickViewFile: 'Click para ver archivo',
  Client: 'Cliente|Clientes',
  Clients: 'Clientes',
  Close: 'Cerrar',
  CollegeOrUniversity: 'Instituto o universidad',
  Combobox: 'Combobox',
  'Coming Soon': 'Coming Soon',
  Commercial: 'Comercial',
  Company: 'Empresa|Empresas',
  CompanyAddress: 'Dirección  de tu empresa',
  companyCreated: 'Cuenta creada con éxito, te vamos a dirigir al aplicativo en breves ',
  CompanyCreateError: 'No se ha podido crear la cuenta, comunícate con tu asesor comercial para brindar mas información',
  CompanyId: 'Empresa',
  CompanyKey: 'Empresa',
  CompanyName: 'Razón Social',
  Complaint: 'Reclamación',
  Components: 'Components',
  Confirm: 'Confirmar',
  ConfirmDelete: 'Esta seguro de eliminar',
  ConfirmDeleteHelper: 'Esta a punto de eliminar el registro {0} {value}',
  Continue: 'Continuar',
  ContractMandante: 'Contrato mandato',
  ContractSignatureStatus: 'Estado aceptación documentos',
  CopyRightCompanyName: 'Copyright © { year } - Aliaddo SAS, Todos los derechos reservados',
  Coupon: 'Cupón',
  CouponCode: 'Código cupón',
  CouponHelper: 'Adicione cupón de descuento para esta facturación',
  CouponLimitedNumberOfTimes: 'Limitado a {value} periodo(s)',
  Create: 'Crear',
  CreateAccount: 'Crear Cuenta',
  CreateAddon: 'Crear Complemento',
  CreateAddOnFor: 'Guardar complemento para {0}',
  CreateCoupon: 'Crear Cupón',
  CreateCouponFor: 'Guardar cupón para {0}',
  Created: 'Creada',
  CreatedAt: 'Fecha creación',
  CreatedBy: 'Creado por',
  CreateOrder: 'Crear Orden|Crear Ordenes|Crear orden cobrando la nueva suscripción',
  CreatePlan: 'Crear Plan',
  CreateYourAccount: 'Crea tu cuenta',
  CreateYourAccountHelper: '¡Empieza desde ya a disfrutar de los beneficios que solo Aliaddo puede ofrecer!',
  CRM: 'CRM',
  Current: 'Actual',
  Dashboards: 'Dashboards',
  DataCompany: 'Datos de tu empresa',
  DataCompanyHelper: 'Ingresa los datos de la cuenta',
  DataExistsModeSave: 'Existen datos existentes, el formulario se ha cargado con los existentes a modo edición',
  DataProcessingPolicy: 'Política de Tratamiento de Datos',
  Datatable: 'Datatable',
  DataUser: 'Tus Datos',
  DataUserHelper: 'Ingresa los datos con los que vas acceder a tu cuenta',
  Date: 'Fecha',
  'Date Picker': 'Date Picker',
  DateEnd: 'Fecha fin',
  DateFormat: 'Formato: dd/mm/yyyy',
  DateStart: 'Fecha de inicio',
  Delete: 'Eliminar',
  Deleted: 'Eliminado',
  Delivery: 'Entregado',
  Description: 'Descripción',
  DescriptionHelper: 'Adiciona una descripción para que tu cliente sepa por esta empresa que le estas cobrando',
  Detail: 'Detalle',
  DetailCompanyId: 'Empresa',
  DetailCompanyKey: 'Empresa',
  Dialog: 'Dialog',
  Disable: 'Deshabilitar',
  Disabled: 'Deshabilitado',
  'Disabled Menu': 'Disabled Menu',
  Discount: 'Descuento|Descuentos',
  Documentation: 'Documentation',
  DocumentType: 'Tipo documento',
  DragAndDropFile: 'Arrastre y suelte o haga clic para cargar el archivo.',
  Due: 'Vence',
  DueDate: 'Fecha vencimiento',
  eCommerce: 'eCommerce',
  Edit: 'Editar',
  DateEarlier: 'La fecha seleccionada no debe ser anterior a',
  DateBeyond: 'La fecha seleccionada no debe ser anterior a',
  ElectronicSignature: 'Firma Electrónica',
  Email: 'Email',
  EmailConfirmed: 'Email confirmado',
  EmailNoConfirmed: 'Email sin confirmar',
  EmailNotifierType: 'Tipo de notificación',
  EmailResetPasswordSent: 'Email de contraseña e ingreso a la cuenta enviado',
  Enable: 'Habilitar',
  Enabled: 'Habilitado',
  EndDate: 'Fecha Fin',
  EndingQuantity: 'Cantidad Final',
  Error: 'Error',
  Event: 'Evento',
  'Expansion Panel': 'Expansion Panel',
  FAQ: 'FAQ',
  Feature: 'Característica|Características|Características {planName}',
  FeaturePlan: 'Características del plan asignado',
  FeaturesPlan: 'Características del plan',
  FieldAlpha: 'El campo {_field_} solo debe contener letras',
  FieldAlphaDash: 'El campo {_field_} solo debe contener letras, números y guiones',
  FieldAlphaNum: 'El campo {_field_} solo debe contener letras y números',
  FieldAlphaSpaces: 'El campo {_field_} solo debe contener letras y espacios',
  FieldBetween: 'El campo {_field_} debe estar entre {min} y {max}',
  FieldConfirmed: 'El campo {_field_} no coincide',
  FieldDigits: 'El campo {_field_} debe ser numérico y contener exactamente {length} dígitos',
  FieldDimensions: 'El campo {_field_} debe ser de {width} píxeles por {height} píxeles',
  FieldDouble: 'El campo {_field_} debe ser un decimal válido',
  FieldEmail: 'El campo {_field_} debe ser un correo electrónico válido',
  FieldExcluded: 'El campo {_field_} debe ser un valor válido',
  FieldExt: 'El campo {_field_} debe ser un archivo válido',
  FieldFeature: 'Variable para controlar acceso característica en el código',
  FieldGreaterThan: 'El dato { value } no es mayor que { greaterThan }',
  FieldHasInvalidCharacter: 'Este campo contiene caracteres no permitidos',
  FieldImage: 'El campo {_field_} debe ser una imagen',
  FieldInteger: 'El campo {_field_} debe ser un entero',
  FieldIs: 'El campo {_field_} no coincide con {other}',
  FieldIsEmailInvalid: 'El dato no corresponde a un email valido',
  FieldIsNot: 'El campo {_field_} debe ser diferente a {other}',
  FieldIsRequired: 'El campo es requerido',
  FieldLength: 'El largo del campo {_field_} debe ser {length}',
  FieldLessThan: 'El dato debe ser menor a {length}',
  FieldMax: 'El campo {_field_} no debe ser mayor a {length} caracteres',
  FieldMaxValue: 'El campo {_field_} debe de ser {max} o menor',
  FieldMimes: 'El campo {_field_} debe ser un tipo de archivo válido',
  FieldMin: 'El campo {_field_} debe tener al menos {length} caracteres',
  FieldMinValue: 'El campo {_field_} debe ser {min} o superior',
  FieldNumeric: 'El campo {_field_} debe contener solo caracteres numéricos',
  FieldOneOf: 'El campo {_field_} debe ser un valor válido',
  FieldRegex: 'El formato del campo {_field_} no es válido',
  FieldRequired: 'El campo {_field_} es obligatorio',
  FieldRequiredIf: 'El campo {_field_} es obligatorio',
  FieldSize: 'El campo {_field_} debe ser menor a {size}KB',
  'File Input': 'File Input',
  FileRut: 'Adjunta el RUT de la empresa',
  FileSizeShouldBeLessThan: 'El tamaño del avatar debe ser inferior a {value} MB!',
  Filter: 'Filtrar|Filtros',
  FilterBy: 'Filtrar por',
  FilteredBy: 'Filtrando por: {value}',
  FiltersApplied: 'Número de filtros aplicado',
  FirstName: 'Nombre|Nombres',
  Forever: 'Vitalicio',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  ForgotPassword: 'Recuperar Contraseña',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  'Forms & Tables': 'Forms & Tables',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Franchise: 'Franquicia',
  FranchiseName: 'Nombre franquicia|Nombres franquicias',
  FrequentQuestion: 'Preguntas frecuentes',
  Friday: 'Viernes',
  FullName: 'Nombres',
  Gamification: 'Gamification',
  GoToStartApp: 'Empieza a disfrutar tu cuenta',
  HasNoPerson: 'No tiene tercero ERP asignado',
  HasPerson: 'Tiene tercero ERP asignado',
  Icons: 'Icons',
  Identification: 'Identificación|Identificaciones',
  IdentificationCheck: 'Dígito Verificación',
  IdentificationType: 'Tipo Identificación|Tipos Identificación',
  Inactive: 'Inactivo',
  Info: 'Informativo',
  Initial: 'Inicial',
  InProcess: 'En Proceso',
  InputForSearch: 'Dígite para buscar',
  Invoice: 'Factura',
  IsForFeature: 'Es para característica',
  IsFree: 'Es Gratis',
  IsPartner: 'Es Socio',
  IsCompany: 'Es Empresa',
  IsPayVat: 'Es responsable de IVA',
  IsSimpleRegime: 'Es régimen simple',
  Item: 'Producto',
  ItemErp: 'Producto Erp',
  Key: 'Key',
  'Knowledge Base': 'Knowledge Base',
  LastIp: 'Ultima Ip',
  LastLogin: 'Ultimo Login',
  LastLoginAt: 'Ultimo Ingreso',
  LastLoginIp: 'Ultima Ip Ingreso',
  LastName: 'Apellido|Apellidos',
  'Leaflet Maps': 'Leaflet Maps',
  Level: 'Nivel',
  LimitedNumberOfTimes: 'Limitado a <strong>{quantity}</strong>  periodo(s)',
  LinkPayment: 'Link de pago',
  List: 'Consultar',
  Log: 'Log',
  LogEmail: 'Log email',
  login: 'Iniciar Sesión',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  Logout: 'Cerrar Sesión',
  MandatorySubscription: 'Obligatorio en apertura de cuenta (En cada apertura se va a cargar en la suscripción)',
  MaximumRedemptionHelper: 'Límite de redenciones por todos los clientes, ejemplo 50 redenciones entonces 50 aperturas',
  Mean: 'Medio|Medios',
  Menu: 'Menu',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Menu Levels': 'Menu Levels',
  MessagePlanNotFound: 'No hemos encontrado el plan, comunícate con tu asesor comercial para validar el plan',
  MessagePlanPriceError: 'Periodo invalido, comuníquese con soporte técnico!',
  MinValue: 'Debe tener un valor mayor a {min}',
  Miscellaneous: 'Miscellaneous',
  Mobile: 'Celular',
  Monday: 'Lunes',
  Month: 'Mes',
  'Month Picker': 'Month Picker',
  Monthly: 'Mensual',
  Name: 'Nombre',
  Neighborhood: 'Barrio',
  New: 'Nuevo',
  NextInvoice: 'Siguiente factura',
  NoAuthorized: 'No esta autorizado para ingresar',
  NoData: 'No se han encontrado datos',
  NoFile: 'No hay archivo',
  NoInvoiceWarning: 'Sin Factura',
  'Not Authorized': 'Not Authorized',
  Note: 'Nota|Notas',
  Ok: 'Ok',
  OneTime: 'Primera vez',
  onSite: 'En el sitio Web',
  Open: 'Vigente',
  Opened: 'Abierto',
  Opps: 'Opps',
  OppsHelper: 'Ha ocurrido un error',
  Optional: '(Opcional)',
  Order: 'Orden|Ordenes',
  OrderStatus: 'Estado Orden',
  Others: 'Others',
  Otro: 'Otro',
  OverwriteFeature: 'Sobrescribir características',
  OverwriteFeaturePlan: 'Sobrescribir características suscripción con las actuales del plan',
  OverwriteWithPlan: 'Sobrescribir con plan',
  Package: 'Paquete|Paquetes',
  PackageHistory: 'Historial Paquetes',
  Pages: 'Pages',
  Pagination: 'Pagination',
  Paid: 'Pagado',
  Paquete: 'Paquete',
  Partner: 'Socio',
  Password: 'Contraseña',
  Pay: 'Pagar',
  Payment: 'Pago|Pagos',
  PaymentCus: 'Cus Transacción',
  PaymentMean: 'Método de pago',
  PaymentMethod: 'Medio de pago',
  PaymentStatus: 'Estado pago|Estados pagos',
  PaymentTransactions: 'Pagos realizados electrónicamente',
  Pending: 'Pendiente',
  Periodicidad: 'Recurrencia',
  Person: 'Tercero contable',
  PersonSeller: 'Tercero Vendedor (ERP)',
  Phone: 'Teléfono',
  PhoneWork: 'Teléfono trabajo',
  PlaceToPay: 'Pagos mediante pasarela de pagos PlaceToPay',
  Plan: 'Plan',
  PlanMode: 'Modalidad plan',
  PleaseSigning: 'Por favor ingresa los datos de inicio de usuario para empezar a trabajar',
  Preview: 'Preview',
  Price: 'Precio|Precios',
  PriceAddOn: 'Precio característica adicional',
  PriceHelper: 'Valor cobrado por el plan completo',
  PriceMonthly: 'Precio Mensual',
  PriceWithDiscount: 'Precio con descuento',
  PriceYearly: 'Precio Anual',
  Pricing: 'Precios',
  PricingInterval: 'I ntervalo precios',
  PricingSchema: 'Esquema precios',
  Process: 'Procesando',
  ProcessSignature: 'Por aceptar',
  Product: 'Producto|Productos',
  ProductCodeInvalid: 'Producto invalido, valida el link de apertura',
  ProductId: 'Producto',
  Prorrateo: 'Prorrateo',
  Quantity: 'Cantidad',
  QuantityAddOn: 'Cantidades adicionales',
  Radio: 'Radio',
  'Raise Support': 'Raise Support',
  'Range Slider': 'Range Slider',
  Rate: 'Tarifa',
  Rating: 'Rating',
  Recipient: 'Destinatario',
  Recurrence: 'Recurrencia',
  Recurring: 'Recurrente',
  RedemptionLimitHelper: 'Máximo de redenciones de este cupón en una suscripción, ejemplo durante los dos primeros meses',
  RedemptionSuccess: 'Felicidades cupón aplicado con éxito',
  Reference: 'Referencia|Referencias',
  Region: 'Departamento',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  RegisterCompanyName: 'Razón social de tu empresa',
  RegisterCompanyNameHelper: 'Razón social de tu empresa',
  RegisterCompanyNameNatural: 'Nombre de tu empresa',
  RegisterCompanyNameNaturalHelper: 'Nombre de tu empresa',
  RegisterDocument: 'Nit de tu empresa',
  RegisterDocumentHelper: 'Nit de tu empresa',
  RegisterDocumentNatural: 'Ingresa tu número de cédula',
  RegisterDocumentNaturalHelper: 'Tu número de documento de identificación (cédula)',
  RegisterDocumentType: 'Tipo documento de tu empresa',
  RegisterEmail: 'Tu correo electrónico (e-mail)',
  RegisterEmailHelper: 'Tu correo electrónico, ejemplo: mi-email@gmail.com',
  RegisterFullName: 'Nombres y Apellidos',
  RegisterFullNameHelper: 'Tus nombres y apellidos completos, ejemplo: Pedro Perez Perez',
  RegisterMessage: 'Estamos emocionados de tenerte como cliente.',
  RegisterMessageHelper: 'Te enviaremos un email con los datos de acceso a tu cuenta <strong>{email}</strong>.',
  RegisterMessageTitle: '¡Gracias por registrarte!',
  RegisterPhone: 'Tu número de celular',
  RegisterPhoneHelper: 'Tu número de celular ejemplo: 3005551100',
  Rejected: 'Rechazada',
  Reload: 'Volver a cargar datos',
  Remove: 'Remover',
  RemoveHelper: 'Esta seguro de remover el registro {0}',
  Replace: 'Reemplazar',
  ReplaceWithPlan: 'Reemplazar con características plan',
  ResendEmailOpening: 'Reenviar email apertura',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  RestoreFeaturePlan: '¿Estas seguro de restaurar las características de la suscripción?',
  RestoreFeaturePlanHelper: 'Al confirmar se van a sobrescribir las características de la suscripción <strong>{value}</strong> con las actuales del plan. ¿Guardamos?',
  Result: 'Resultado',
  Retention: 'Retención|Retenciones',
  Revert: 'Retroceder pago',
  RevertPayment: 'Retroceder pago',
  Rol: 'Rol',
  Rut: 'Rut',
  SalesByVendor: 'Ventas por comercial',
  Saturday: 'Sabado',
  Save: 'Guardar',
  SaveOverwrite: 'Guardar plan sobrescribiendo características actuales del plan',
  SaveOverwriteHelper: 'Al guardar sobrescribiendo las características de las suscripción  con las actuales del plan, se van a eliminar también los adicionales',
  SavePlan: 'Guardar Plan',
  SavePlanFor: 'Guardar plan <strong>{0}</strong>',
  School: 'Institución Educativa',
  Search: 'Buscar',
  SearchReference: 'Cus o Consecutivo orden',
  Select: 'Seleccionar',
  SelectAddOns: 'Complementos',
  SelectAddOnsHelper: 'Selecciona las características adicionales al plan',
  SelectCompany: 'Seleccionar Empresa',
  SelectCompanyHelper: 'Que empresa vas a facturar, para que el cliente sepa a que empresas corresponden los cobros',
  SelectCompanyOrder: 'Selecciona la empresa a la que se le facturó o empresa partner',
  SelectCompanyOrderDetail: 'Selecciona la empresa que esta en el detalle de la orden o empresa hija',
  SelectCreditCard: 'Seleccione tarjeta de crédito',
  SelectFeature: 'Selecciona una característica',
  SelectFeatures: 'Características',
  SelectFeaturesHelper: 'Módulos o características que contiene el plan o paquete',
  SelectNewStatus: '¿Cuál es el estado con el que deseas dejar de ahora en adelante a <strong> {companyName} </strong> en el servicio <strong>{productName}</strong>?',
  SelectOrderDetailTypeHelper: '¿Qué tipo de servicio vas a facturar, paquete, apertura, cambio de plan?',
  SelectPerson: 'Selecciona el tercero para la empresa {companyName} y asi poder crear la factura electronica y enviarla al correo',
  SelectPersonSeller: 'Seleccionar el tercero vendedor del erp para relacionar las facturas generadas desde el voucher',
  SelectPlan: 'Selecciona plan',
  SelectPlanHelper: '¿Que plan vas a facturar?',
  SelectProduct: 'Seleccione servicio',
  SelectProductHelper: 'Que servicio vas a facturar?',
  SaveEndTrial: 'Guardar Periodo Prueba',
  SelectRolForUser: 'Selecciona el nuevo rol para el usuario ({value})',
  SelectRolHelper: 'Selecciona el nuevo rol para los ({quantity}) usuario(s) seleccionados',
  SelectType: 'Selecciona Tipo',
  SelectUserStatusHelper: 'Selecciona el nuevo estado para los ({quantity}) usuario(s) seleccionados',
  SelectVendor: 'Selecciona el asesor comercial para las empresas seleccionadas ({value}), este se aplicará para cada una de las suscripciones o servicios que tenga con Aliaddo',
  SelectYourFile: 'Selecciona tu archivo',
  Seller: 'Comercial',
  SellerErp: 'Vendedor del ERP',
  SellerUserId: 'Comercial',
  Send: 'Enviar',
  SendEmail: 'Enviar email',
  SendEmailOpening: 'Enviar correo de apertura',
  SendEmailOpeningHelper: 'Enviar email de apertura y restablecer contraseña',
  Sent: 'Envidado',
  Service: 'Servicio|Servicios',
  SetPerson: 'Asignar tercero para facturación',
  SetRol: 'Asignar Rol',
  SetStatus: 'Asignar Estado',
  SetVendor: 'Asignar Comercial',
  Signature: 'Firma|Firmas',
  SignatureDocument: 'Documentos para firmar con Aliaddo',
  SignatureStatus: 'Estado aceptación firma con Aliaddo',
  SignatureStatusBy: 'Agente que aceptó o asignó el último estado a los documentos',
  SignatureStatusDate: 'Fecha en que el agente aceptó o asignó el último estados a los documentos',
  Signup: 'Crea tu cuenta',
  'Simple Table': 'Simple Table',
  Slider: 'Slider',
  Snackbar: 'Snackbar',
  StartDate: 'Fecha Inicio',
  StartingQuantity: 'Cantidad Inicial',
  StartInvoice: 'Inicio facturación',
  Statistics: 'Statistics',
  Status: 'Estado|Estados',
  StatusAt: 'Actualizado En',
  StatusBy: 'Actualizado Por',
  StatusReason: 'Razones del estado',
  IssueDate: 'Fecha Emisión',
  Reason: 'Razón|Razones',
  Stepper: 'Stepper',
  Student: 'Estudiante|Estudiantes',
  StudentCard: 'Carnet Estudiantil',
  StudentCardStatus: 'Estado certificado matricula',
  Subscription: 'Suscripción|Suscripciones',
  SubscriptionActives: 'Suscripciones Activos',
  SubscriptionFeature: 'Características suscripción',
  Subtotal: 'Subtotal',
  SubTotal: 'SubTotal',
  Success: 'Éxito',
  SuccessCreated: 'Creado con éxito',
  SuccessDeleted: 'Registro eliminado con éxito',
  SuccessPay: 'Pagado con éxito',
  SuccessRevert: 'Pago revertido correctamente',
  SuccessSaved: 'Registro guardado con éxito',
  SuccessSend: 'Enviado con éxito',
  Summary: 'Resumen',
  Sunday: 'Domingo',
  Surname: 'Apellidos',
  Switch: 'Switch',
  Tables: 'Tables',
  Tabs: 'Tabs',
  TakeFromPlan: 'Tomar las del plan',
  TakePlan: 'Tomar las del plan',
  Tax: 'Impuesto|Impuestos',
  Taxes: 'Impuestos',
  Tenant: 'Tenant',
  TermsAndConditions: 'Términos y condiciones',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  Thursday: 'Jueves',
  Tier: 'Capa',
  'Time Picker': 'Time Picker',
  Timeline: 'Timeline',
  Tooltip: 'Tooltip',
  Total: 'Total',
  TotalAddOn: 'Total adicionales',
  TotalAmount: 'Monto Total',
  TotalWithDiscount: 'Total con descuento',
  Treeview: 'Treeview',
  TrialEnd: 'Fin periodo de prueba',
  Tuesday: 'Martes',
  Type: 'Tipo',
  TypeForSearch: 'Empieza a escribir para buscar',
  Typography: 'Typography',
  Unauthorized: 'No Autorizado',
  UnauthorizedHelper: 'Lamentamos informarte que no tienes acceso a los recursos solicitados. Si crees que esto es un error o necesitas asistencia adicional, por favor cuéntanoslo a nuestro equipo de soporte',
  'Under Maintenance': 'Under Maintenance',
  Unit: 'Unitario',
  UnPaid: 'Ordenes Vigentes',
  Update: 'Actualizar',
  UpdatedAt: 'Actualizado el',
  UpdatedBy: 'Actualizado por',
  UpdateFeaturePlan: 'Actualizar características suscripción con las del plan',
  UpdatePackage: 'Actualizar paquete',
  UpdateProfile: 'Actualizar perfil',
  UpdateStatus: 'Actualizar Estado',
  UpdateSubscriptions: 'Actualizar características de todas las suscripciones que tengan el plan',
  Used: 'Usado',
  User: 'Usuario',
  'User Interface': 'User Interface',
  'USER INTERFACE': 'USER INTERFACE',
  'User List': 'User List',
  'User View': 'User View',
  UserCompany: 'Usuario Empresa|Usuarios Empresa',
  UserEmail: 'Email usuario',
  UserHub: 'Usuario Hub|Usuarios Hub',
  UsersTenant: 'Usuarios Empresas',
  ValidateForm: 'Valida los datos ingresados',
  ValidateFormHelper: 'Existen campos vacíos en el formulario que debes completar.',
  ValidateSignature: 'Validar Firmas',
  ValidateStudent: 'Validar Estudiantes',
  Value: 'Valor',
  ViewEmailLog: 'Ver log email',
  ViewFile: 'Ver archivo',
  ViewPayment: 'Ver Pago|Ver Pagos',
  ViewPrices: 'Ver Precios',
  Volume: 'Volumen',
  Warning: 'Advertencia',
  Wednesday: 'Miercoles',
  WelcomeApp: 'Bienvenido a Aliaddo',
  WhenCreatingTheAccount: 'Al crear tu cuenta aceptas nuestros',
  WithDiscount: 'Con descuento',
  Year: 'Año',
  Yearly: 'Anual',
  YouAreBilling: 'Estas facturando: {value}',
  YouAreGoingToInvoice: '¿Que le vas a facturar al cliente?',
  YourIp: 'Tu Ip:',
  YourPlan: 'Tu plan',
  Address: 'Dirección',
  Website: 'Sitio Web',
  OrderYear: 'Año Orden',
  OrderMonth: 'Mes Orden',
  DateEarlierMessage: 'La fecha {startDate} debe ser antes de {endDate}',
  DateBeyondMessage: 'La fecha {endDate} debe ser después de {startDate}',
  UserOwner: 'Usuario propietario',
  EmailOwner: 'Email propietario',
  Quarterly: 'Trimestral',
  Semiannual: 'Semestral',
  YourPlanSelect: 'Plan seleccionado',
  ChargePer: 'Cobro por',
  Onetime: 'Único cobro',
  DescriptionByDetail: 'Descripción  a nivel detalle voucher',
  Caray: 'Caray',
  ConfirmMessageTitle: 'Email confirmado con éxito',
  ConfirmMessageHelper: 'Ya puedes continuar con tu nuevo email <strong>{email}</strong>',
  NoConfirmMessageTitle: 'No hemos podido confirmar tu email',
  CancelHelper: 'Estas seguro de anular la orden {consecutive}, esta acción no se puede deshacer',
  AddEntry: 'Adicionar',
  Apply: 'Aplicar',
  DescriptionByDetailHelper: 'Esta descripción es visible solo cuando el cliente pague, no va a quedar en la factura, para adicionar una descripción a la factura llena afuera las notas.'
}
