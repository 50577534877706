import { toDate } from '@/utils'

/**
 * Compares the selected date if earlier or beyond from the date provided
 */
export default ({ startDate, endDate, validationType }) => {
  let pass = true
  if (toDate(startDate) instanceof Date && toDate(endDate) instanceof Date) {
    const valueDate = toDate(startDate)
    const compDate = toDate(endDate)

    pass = validateDate(valueDate, compDate, validationType)
  }

  if (Array.isArray(startDate) && toDate(endDate) instanceof Date) {
    const compDate = toDate(endDate)

    for (let i = 0; i < startDate.length; i++) {
      const valueDate = toDate(startDate[i])

      pass = validateDate(valueDate, compDate, validationType)

      if (!pass) return pass
    }
  }

  if (Array.isArray(endDate) && toDate(startDate) instanceof Date) {
    const valueDate = toDate(startDate)

    for (let i = 0; i < endDate.length; i++) {
      const compDate = toDate(endDate[i])

      pass = validateDate(valueDate, compDate, validationType)

      if (!pass) return pass
    }
  }

  return pass
}

const validateDate = (value, compare, validationType) => {
  let pass = true
  if (validationType === 'earlier') {
    pass = isEarlier(value, compare)
  }

  if (validationType === 'beyond') {
    pass = isBeyond(value, compare)
  }

  return pass
}

const isEarlier = (start, end) => {
  return start < end
}

const isBeyond = (start, end) => {
  return end > start
}
