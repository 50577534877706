<template>
  <layout-blank>
    <slot />
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import { registerSharedStoreModule } from '@/components/sharedStoreModule'

export default {
  components: {
    LayoutBlank
  },
  setup () {
    registerSharedStoreModule()
  }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/overrides.scss';
@import '@core/preset/preset/skins/bordered.scss';
@import '@core/preset/preset/skins/semi-dark.scss';
</style>
